import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const PageAuthors = props => {

  return (
    <Layout {...props}>
      <Seo title='Our Team' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Comment Policy'
          subheader='Highlighting the rules of our community'
        />
      </Stack>
      <Stack>
        {/* https://blog.disqus.com/how-to-create-an-effective-comment-policy-that-readers-actually-follow */}
        <Main>
          Comments are reviewed prior to publication. Comments must contribute to the discussion; debate an opinion, not a person.

          “Why is my comment stuck in pending?”

          “How do I contact a moderator about my comment(s)?”

          “Why are comments closed for this discussion?”


        </Main>
      </Stack>
    </Layout>
  )
}

export default PageAuthors
